<template>
  <div>
    <!-- 头部样式 左侧为功能title  右侧为业务按钮 -->
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t('cip.plat.sys.serialNumber.title.indexHeadTitle')"
                 @head-add="handleAdd"
                 @head-romve="headRomve"></head-layout>
    <!-- 对表单数据的搜索样式 -->
    <div class="headers">
      <grid-head-layout ref="searchFrom"
                        :search-columns="searchColumns"
                        v-model="searchForm"
                        @grid-head-search="searchChange"
                        @grid-head-empty="searchReset">
      </grid-head-layout>
    </div>
    <!-- 表格 -->
    <grid-layout ref="gridLayOut"
                 :tableOptions="tableOptions"
                 :table-data="dataParent"
                 :table-loading="loading"
                 :data-total="dataTotal"
                 :page="page"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @gird-handle-select-click="selectionChange"
                 :gridRowBtn="gridRowBtn"
                 @grid-row-detail-click="handleView"
                 @grid-edit="handleEdit"
                 @grid-romve="rowDel">
    </grid-layout>
  </div>
</template>
<script>
import {getList,remove} from "@/api/system/serialNumber";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import {mapGetters} from "vuex";

export default {
  components:{HeadLayout,GridLayout},
  name: "index",
  data() {
    return {
      searchForm:[],
      selectionList:[],
      searchColumns: [
        {
          label: "",
          prop: "ruleCode",
          placeholder: this.$t("cip.plat.sys.serialNumber.field.ruleCode"),
        },
        {
          label: "",
          prop: "ruleName",
          placeholder: this.$t("cip.plat.sys.serialNumber.field.ruleName"),
        },
      ],
      dataParent: [],//表格数据
      dataTotal: 0,//总数据量
      tableOptions: {
        menuWidth: 250,
        linklabel: "ruleCode",
        column: [
          {
            label: this.$t('cip.plat.sys.serialNumber.field.ruleCode'),
            headerAlign: "center",
            overHidden: true,
            width:200,
            prop: "ruleCode",
          },
          {
            label: this.$t('cip.plat.sys.serialNumber.field.ruleName'),
            prop: "ruleName",
            overHidden: true,
            align:'left',
          },
          {
            label: this.$t('cip.plat.sys.serialNumber.field.ruleType'),
            prop: "ruleType",
            type: "select",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=biz_num_rule",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            headerAlign: "center",
            align:'center',
          },
          {
            label: this.$t('cip.plat.sys.serialNumber.field.serialLength'),
            prop: "serialLength",
            headerAlign: "center",
            align:'right',
          },
          {
            label: this.$t('cip.plat.sys.serialNumber.field.currentValue'),
            prop: "currentValue",
            headerAlign: "center",
            align:'right',
          },
          {
            label: this.$t('cip.plat.sys.serialNumber.field.initValue'),
            prop: "initValue",
            headerAlign: "center",
            align:'right',
          },
          {
            label: this.$t('cip.plat.sys.serialNumber.field.step'),
            prop: "step",
            headerAlign: "center",
            align:'right',
          },
          {
            label: this.$t('cip.plat.sys.serialNumber.field.prefix'),
            prop: "prefix",
            headerAlign: "center",
            align:'center',
          },
          {
            label: this.$t('cip.plat.sys.serialNumber.field.curTime'),
            prop: "curTime",
            hide: true,
            type: "datetime",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
            headerAlign: "center",
            align:'center',
          },
          {
            label: this.$t('cip.plat.sys.serialNumber.field.systemTime'),
            prop: "systemTime",
            type: "datetime",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
            hide: true,
            headerAlign: "center",
            align:'center',
          },
          {
            label: this.$t('cip.plat.sys.serialNumber.field.split'),
            prop: "split",
            headerAlign: "center",
            align:'center',
          }
        ]
      },
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      data: []
    }
  },
  computed:{
    ...mapGetters(["userInfo", "permission", 'colorName']),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let   buttonBtn = [];
      if (this.permission.serialNumber_add) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.addBtn"),
            emit: "head-add",
            type: "button",
            icon: ""
          });
      }
      if (this.permission.serialNumber_delete) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "head-romve",
            type: "button",
            icon: ""
          });
      }
      return buttonBtn;
    },
    gridRowBtn() {
      let   buttonBtn = [];
      if (this.permission.serialNumber_edit) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.editBtn"),
            emit: "grid-edit",
            type: "text",
            icon: ""
          });
      }
      if (this.permission.serialNumber_delete) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "grid-romve",
            type: "text",
            icon: ""
          })
      }
      return buttonBtn;
    },
  },
  methods: {
    headRomve() {
      this.handleDelete()
    },
    //新增
    handleAdd() {
      this.$router.push({
        path: '/system/serialNumber/add',
        query: {
          type: 'add',
        }
      });
    },
    //修改
    handleEdit(row) {
      this.$router.push({
        path: '/system/serialNumber/edit',
        query: {
          id: row.id,
          type: 'edit',
        }
      })
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          remove(row.id).then(() => {
            this.$message({
              type: "success",
              message: this.$t('cip.cmn.msg.success.operateSuccess')
            });
            this.onLoad(this.page);
          });
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
        });
    },
    handleView(row) {
      this.$router.push({
        path: '/system/serialNumber/view',
        query: {
          id: row.id,
          type: 'view',
        }
      })
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
        });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.loading = true;
      getList(page.currentPage, page.pageSize, this.query).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.dataTotal = data.total;
        this.dataParent = data.records;
        this.loading = false;
        this.selectionClear();
      });
    }
  },
  created(){
    this.onLoad(this.page);
  }
}
</script>

<style scoped>

</style>
